.bonus_wrapper{
    justify-content: center;

    .bonus_container{
        margin-right: 70px;

        img{
            height: 150% !important;
        }
    }
}
.btn.bonus {
    margin-top: -5px;
}

.search_icon {
    @include recolor(#e52a91);
}

.search_icon:hover{
    @include recolor(#e354a3);

}
.header-logo{
    width: initial;

}
.bonus_wrapper{
    width: 100vw;
}
.carousel-initialized{
    padding: 0 !important;
}

@media screen and (max-width: 600px) {
    .bonus_wrapper{
        justify-content: center;
        .bonus_container{
            margin-right: 0px;
        }
    }
    .btn.bonus {
        margin-top:0px;
        height: initial;
    }

}